import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Card, Col, Divider, Row, Spin } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import openNotification from '../../../components/Toastr';
import ShipmentTypeTag from '../../shipments/components/ShipmentTypeTag';
import ShipmentStatusTag from '../../shipments/components/ShipmentStatusTag';
import ReviewDetailContent from './steps/components/ReviewDetailContent';
import ReviewShipment from './steps/components/ReviewShipment';
import ReviewLabelsDocuments from './steps/components/ReviewLabelsDocuments';
import orderSalesApi from '../../../api/order-sales';
import couriersApi from '../../../api/courier';

function SaleOrderReview() {
  const { t } = useTranslation();

  const { saleOrderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [saleOrderData, setSaleOrderData] = useState();
  const [products, setProducts] = useState();

  const [isNational, setIsNational] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState({});

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [orderStatus, setOrderStatus] = useState('Working');
  const language = useSelector((store) => store.Session.language);

  const [mergedAddress, setMergedAddress] = useState('');
  const [warehouse, setWarehouse] = useState({});
  const [boxes, setBoxes] = useState([]);
  const [commercialInvoiceRequirements, setCommercialInvoiceRequirements] =
    useState();

  const getCommercialInvoiceRequirements = async () => {
    try {
      const response = await couriersApi.commercialInvoiceRequirements(
        selectedCourier.name,
        warehouse.address.countryCode,
        saleOrderData?.destinationAddress?.countryCode
      );
      setCommercialInvoiceRequirements(response.data);
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.documents.error'),
      });
    }
  };

  const getSaleOrderData = async () => {
    setLoading(true);
    try {
      const saleOrderResponse = await orderSalesApi.getSaleOrder(saleOrderId);
      setSaleOrderData(saleOrderResponse.data);
      const productsResponse = await orderSalesApi.getProductsByOrderId(
        saleOrderId
      );

      const productCompleteData = productsResponse.data.map((product) => {
        const saleOrderProduct = saleOrderResponse.data.orderItems.find(
          (item) => item.sku === product.defaultCode
        );
        return {
          product: {
            ...product,
          },
          quantity: saleOrderProduct.quantity,
        };
      });
      setProducts(productCompleteData);

      setIsNational(
        saleOrderResponse.data.courierShipments[0].requestedShipment.shipper
          .address.countryCode ===
          saleOrderResponse.data.destinationAddress.countryCode
      );
      const courierResponse = await couriersApi.getCourierCredentials(false, 1);
      const data = courierResponse.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      const actualCourier = data.find(
        (courier) => courier.name === saleOrderResponse.data.fbmCourier
      );
      setSelectedCourier(actualCourier);

      const actualDate = new Date(
        saleOrderResponse.data.createdAt
      ).toLocaleDateString(language, {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
      const [day, month, year] = actualDate.split(' ');
      setName(
        `[PEDIDOS] ${day}-${month.toUpperCase()}-${year} - ${
          saleOrderResponse?.data.marketplace
        }`
      );
      setDate(`${day}-${month.toUpperCase()}-${year}`);

      if (
        (saleOrderResponse?.data.marketplace === 'Edicommerce' &&
          saleOrderResponse?.data.orderFulfillmentStatus === 'Canceled') ||
        (!['Edicommerce'].includes(saleOrderResponse?.data.marketplace) &&
          (['Canceled', 'Unfulfillable'].includes(
            saleOrderResponse?.data.orderStatus
          ) ||
            (saleOrderResponse?.data.orderStatus === 'Shipped' &&
              ['Canceled', 'Unfulfillable', 'Invalid'].includes(
                saleOrderResponse?.data.orderFulfillmentStatus
              )) ||
            saleOrderResponse?.data.orderStatus === 'Incident'))
      ) {
        setOrderStatus('Cancelled');
      } else if (
        saleOrderResponse?.data.orderFulfillmentStatus === 'Completed' &&
        ((saleOrderResponse?.data.orderStatus === 'Shipped' &&
          saleOrderResponse?.data.marketplace !== 'Edicommerce') ||
          saleOrderResponse?.data.marketplace === 'Edicommerce')
      ) {
        setOrderStatus('Shipped');
      }

      setMergedAddress(
        `${saleOrderResponse?.data?.destinationAddress?.city}, ${saleOrderResponse?.data?.destinationAddress?.countryCode}`
      );

      if (saleOrderResponse?.data?.warehouse) {
        setWarehouse(saleOrderResponse?.data?.warehouse);
      }
      if (saleOrderResponse?.data?.boxes) {
        const completeBoxes = saleOrderResponse?.data?.boxes.map((item) => ({
          ...item,
          box: {
            isPallet: false,
          },
        }));
        setBoxes(completeBoxes);
      }
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener el pedido.',
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (!saleOrderData) {
      getSaleOrderData();
    }
  }, []);

  useEffect(() => {
    if (warehouse && saleOrderData) getCommercialInvoiceRequirements();
  }, [warehouse, saleOrderData]);

  return (
    <Col
      style={{ paddingTop: 80, paddingLeft: 50, paddingRight: 50 }}
      span={24}
    >
      <Card className="shipment-main-card">
        <div id="boxes" className="text-align-left">
          <Spin spinning={loading}>
            <Row style={{ fontSize: '24px', fontWeight: 500 }}>
              {t('orders.shipment.summary.title2')}
            </Row>
            <Row
              style={{ fontSize: '16px', marginBottom: '20px' }}
              justify="space-between"
            >
              <Col>{name}</Col>
              <Col>
                <Row>
                  <ShipmentTypeTag
                    text={t('shipments.types.saleOrder')}
                    icon={<ShoppingCartOutlined />}
                  />
                  <Divider type="vertical" />
                  {date}
                  <Divider type="vertical" />
                  <ShipmentStatusTag status={orderStatus} type="SaleOrder" />
                </Row>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <ReviewDetailContent
                  selectedCourier={selectedCourier}
                  name={name}
                  destination={mergedAddress}
                  warehouse={warehouse}
                  shipmentType={t('common.order')}
                  isNational={isNational}
                  products={products}
                />
              </Col>
              <Col span={24}>
                <ReviewShipment
                  courierShipments={
                    saleOrderData?.courierShipments
                      ? saleOrderData.courierShipments
                      : []
                  }
                  selectedCourier={selectedCourier}
                  getOrder={getSaleOrderData}
                  boxes={boxes}
                />
              </Col>
              <Col span={24}>
                <ReviewLabelsDocuments
                  courierShipments={
                    saleOrderData?.courierShipments
                      ? saleOrderData.courierShipments
                      : []
                  }
                  isNational={isNational}
                  commercialInvoiceRequirements={commercialInvoiceRequirements}
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </Card>
    </Col>
  );
}

export default SaleOrderReview;
