/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { PrinterOutlined, SendOutlined } from '@ant-design/icons';
import { Affix, Button, Col, Divider, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import couriersAPI from '../../../../../../api/courier';
import openNotification from '../../../../../../components/Toastr';
import ShipsmartServiceSelect from '../components/ShipsmartServiceSelect';
import { urlToBase64 } from '../../../../../../utils/functions';
import FBMCouriers from '../../../../../../utils/FBMCouriers';
import restockOrdersAPI from '../../../../../../api/restock-orders';
import { orderGeneralStates } from '../../../../../../utils/const';
import { getErrorMessage } from '../../../../../../api/api';
import shipmentsAPI from '../../../../../../api/shipments';

function ShipsmartPickupSummaryStep(
  {
    orders,
    restockOrderId,
    fboOrigin,
    data,
    setData,
    setLoadingConfirmShipments,
    hideSendButton,
  },
  ref
) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isRatesLoading, setIsRatesLoading] = useState(true);
  const [productSend, setProductSend] = useState(false);
  const [courierShipments, setCourierShipments] = useState([]);
  const [rates, setRates] = useState({});
  const [allRates, setAllRates] = useState([]);

  const getOrderRates = async () => {
    const allRatesFromAllShipments = [];
    const ratesByOrder = {};

    for (const shipment of data) {
      const payload = {
        ...shipment,
        saleOrderId: shipment.saleOrderId,
        warehouseId: shipment.warehouseId,
        boxes: shipment.boxes,
        thirdPartyShipmentId: shipment.thirdPartyShipmentId,
        restockOrderId,
      };
      try {
        const response = await couriersAPI.getRatesAndTransitTimes(
          'Shipsmart',
          payload
        );
        const finalCarries =
          response?.data?.carriers?.map((carrier) => {
            return {
              ...carrier,
              lapQuotationId: response.data.quotation,
              lapCurrency: response.data.currency,
            };
          }) || [];
        allRatesFromAllShipments.push(...finalCarries);
        ratesByOrder[
          shipment.saleOrderId ||
            shipment.thirdPartyShipmentId ||
            shipment.restock?.id
        ] = finalCarries;
      } catch (error) {
        openNotification({ status: false, content: getErrorMessage(error) });
      }
    }
    setAllRates(ratesByOrder);
    const repeatedObjects = allRatesFromAllShipments.filter(
      (obj, index, arr) => {
        return arr.findIndex((o) => o.code === obj.code) !== index;
      }
    );

    setRates(
      Object.keys(ratesByOrder).length > 1
        ? repeatedObjects
        : allRatesFromAllShipments
    );
    setIsRatesLoading(false);
  };

  useEffect(() => {
    if (data.length > 0 && !data.find((s) => s.shipsmartData)) getOrderRates();
  }, [data]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const onFinish = async () => {
    let createShipmentCourierResponse;
    try {
      setIsLoading(true);
      if (setLoadingConfirmShipments) {
        setLoadingConfirmShipments(true);
      }
      if (data.length > 0) {
        if (data[0].saleOrderId) {
          const createShipmentPromises = data.map((shipment) => {
            const payload = {
              orderId: shipment.saleOrderId,
              shipment: {
                courierName: 'Shipsmart',
                serviceType: {
                  name: shipment.shipsmartData.quotationData?.name,
                  code: shipment.shipsmartData.quotationData?.code,
                  externalId:
                    shipment.shipsmartData.quotationData.lapQuotationId,
                },
                pickup: {
                  type: 'DROP_OFF',
                },
                rate: {
                  value: parseFloat(
                    shipment.shipsmartData?.quotationData?.currency_quote_amount
                  ),
                  currency: shipment.shipsmartData?.quotationData?.lapCurrency,
                },
              },
            };
            return shipmentsAPI.createSalesOrderShipment(payload);
          });
          createShipmentCourierResponse = await Promise.all(
            createShipmentPromises
          );
        } else if (data[0].thirdPartyShipmentId) {
          createShipmentCourierResponse =
            await couriersAPI.createMultipleCourierShipments(
              FBMCouriers.SHIPSMART,
              data.map((d) => ({ ...d, fboOrigin }))
            );
        } else if (restockOrderId) {
          const createShipmentPayloads = data.map((shipment) => ({
            order: {
              id: parseInt(restockOrderId, 10),
              shipmentId: shipment.ShipmentId,
            },
            shipment: {
              courierName: 'Shipsmart',
              serviceType: {
                name: shipment.shipsmartData.quotationData?.name,
                code: shipment.shipsmartData.quotationData?.code,
                externalId: shipment.shipsmartData.quotationData.lapQuotationId,
              },
              pickup: {
                type: 'DROP_OFF',
              },
              rate: {
                value: parseFloat(
                  shipment.shipsmartData?.quotationData?.currency_quote_amount
                ),
                currency: shipment.shipsmartData?.quotationData?.lapCurrency,
              },
            },
          }));
          for (const p of createShipmentPayloads) {
            let attempt = 0;
            while (attempt < 3) {
              try {
                await shipmentsAPI.createRestockOrderShipment(p);
                break;
              } catch (error) {
                attempt += 1;
                if (attempt === 3) throw error;
              }
            }
          }
          await restockOrdersAPI.submitFBAInboundCartonContent(restockOrderId);
          await restockOrdersAPI.update(restockOrderId, {
            state: orderGeneralStates.READY_TO_SHIP,
          });
        }
      }
      setCourierShipments(createShipmentCourierResponse.data);
      openNotification({
        status: true,
        content: t('orders.shipment.summary.success'),
      });
      setProductSend(true);
      setIsLoading(false);
      if (setLoadingConfirmShipments) {
        setLoadingConfirmShipments(false);
      }
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.shipment.summary.shipmentError'),
      });
      setIsLoading(false);
      if (setLoadingConfirmShipments) {
        setLoadingConfirmShipments(false);
      }
      throw error;
    }
  };

  useImperativeHandle(ref, () => ({
    onFinish,
  }));

  const downloadCourierShipmentsLabels = async () => {
    if (courierShipments.length > 0) {
      const trackingNumbers = courierShipments.map(
        (courierShipment) => courierShipment.masterTrackingNumber
      );
      try {
        const response = await couriersAPI.getMultipleShipmentDocument(
          FBMCouriers.SHIPSMART,
          trackingNumbers
        );
        response.data.forEach(async (label) => {
          if (label.url) {
            const downloadLink = document.createElement('a');
            downloadLink.target = '_blank';
            if (label.url.startsWith('http')) {
              const pdf = await urlToBase64(label.url);
              downloadLink.href = pdf.replace(
                'data:application/octet-stream',
                'data:application/pdf'
              );
            } else {
              downloadLink.href = label.url;
            }
            const fileName = `etiqueta_${label.docType}.pdf`;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        });
      } catch (error) {
        openNotification({
          status: false,
          content: 'Ha ocurrido un error al intentar descargar las etiquetas.',
        });
      }
    }
  };
  return (
    <>
      <Col span={24}>
        {isRatesLoading ? (
          <Typography.Text className="grey-box">
            Calculando costos de envío...
          </Typography.Text>
        ) : (
          <ShipsmartServiceSelect
            rates={rates}
            data={data}
            setData={setData}
            allRates={allRates}
          />
        )}
      </Col>
      <Col span={24}>
        {data?.map((shipment) => (
          <Row
            style={{
              border: '1px solid #C4C4C4',
              borderRadius: '8px',
              padding: '20px 36px',
              marginBottom: '20px',
            }}
            key={shipment.saleOrderId}
          >
            <Col span={6}>
              <Row className="summary-title">
                {t('orders.shipment.summary.content')}:
              </Row>
              <Col>
                <Row style={{ marginBottom: '40px' }}>
                  <div className="grey-box">
                    {t('common.boxes')}: {shipment.boxes.length}
                  </div>
                </Row>
                <Row style={{ marginBottom: '40px' }}>
                  <div className="grey-box">
                    {t('orders.shipment.summary.products')}:{' '}
                    {orders &&
                      orders.find(({ id }) => id === shipment.saleOrderId)
                        .orderItems.length}
                    {restockOrderId && shipment.Items.length}
                    {shipment.thirdPartyShipmentId &&
                      shipment.commodities.length}
                  </div>
                </Row>
              </Col>
            </Col>
            <Col span={1}>
              <Divider
                type="vertical"
                style={{ height: '100%', marginLeft: '50%' }}
              />
            </Col>
            <Col span={17}>
              <Row className="summary-title">
                {t('orders.shipment.summary.products')}:
              </Row>
              {orders &&
                orders
                  .find(({ id }) => id === shipment.saleOrderId)
                  .orderItems.map((product) => {
                    return (
                      <div key={product.id}>
                        <Row>
                          <div style={{ fontSize: '18px', color: '#67A4FF' }}>
                            {product.title}
                          </div>
                        </Row>
                        <Row>
                          {t('orders.shipment.summary.sendTo')}:{' '}
                          {
                            orders.find(({ id }) => id === shipment.saleOrderId)
                              .destinationAddress.address1
                          }
                        </Row>
                      </div>
                    );
                  })}
              {restockOrderId &&
                shipment.Items.map((product) => {
                  return (
                    <div key={product.selleSKU}>
                      <Row>
                        <div style={{ fontSize: '18px', color: '#67A4FF' }}>
                          {product.title}
                        </div>
                      </Row>
                      <Row>
                        {t('orders.shipment.summary.sendTo')}:{' '}
                        {shipment.ShipToAddress.City}
                      </Row>
                    </div>
                  );
                })}
              {shipment.thirdPartyShipmentId &&
                shipment.commodities.map((product) => {
                  return (
                    <div key={product.id}>
                      <Row>
                        <div style={{ fontSize: '18px', color: '#67A4FF' }}>
                          {product.title}
                        </div>
                      </Row>
                      <Row>
                        {t('orders.shipment.summary.sendTo')}:{' '}
                        {shipment.recipients[0].address.streetLines}
                      </Row>
                    </div>
                  );
                })}
              <Row
                justify="end"
                style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
              >
                <Typography.Text className="grey-box">
                  {!isRatesLoading
                    ? `Costo de envío: ${shipment?.shipsmartData?.quotationData?.currency_quote_price} ${shipment?.shipsmartData?.quotationData?.lapCurrency}`
                    : 'Calculando costo...'}
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        ))}
        {data.length > 0 && (
          <Row justify="end">
            <Typography.Text
              className="w-600"
              style={{
                backgroundColor: '#00E5A666',
                padding: 10,
                borderRadius: 4,
              }}
            >
              {`Costo total de envío: ${Object.values(data).reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  Number(
                    currentValue?.shipsmartData?.quotationData
                      ?.currency_quote_price
                  ),
                0
              )} ${
                data[0]?.shipsmartData?.quotationData?.lapCurrency ?? 'N/A'
              }`}
            </Typography.Text>
          </Row>
        )}
        <Row justify="end" style={{ marginTop: 24 }}>
          {productSend && (
            <Col style={{ marginRight: 24 }}>
              <Affix offsetBottom={10}>
                <Space>
                  <Button
                    className="ant-btn"
                    onClick={downloadCourierShipmentsLabels}
                  >
                    {t('common.print')} <PrinterOutlined />
                  </Button>
                </Space>
              </Affix>
            </Col>
          )}
          {!hideSendButton && (
            <Col>
              <Affix offsetBottom={10}>
                <Space>
                  <Button
                    className={
                      productSend
                        ? 'btn-link-filled-disabled'
                        : 'btn-link-filled'
                    }
                    icon={<SendOutlined />}
                    onClick={onFinish}
                    loading={isLoading}
                    disabled={productSend}
                  >
                    {t('common.send')}
                  </Button>
                </Space>
              </Affix>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
}

ShipsmartPickupSummaryStep.propTypes = {
  orders: PropTypes.arrayOf(Object),
  restockOrderId: PropTypes.arrayOf(Object),
  data: PropTypes.instanceOf(Object).isRequired,
  fboOrigin: PropTypes.instanceOf(Object),
  setData: PropTypes.func.isRequired,
  setLoadingConfirmShipments: PropTypes.func,
  hideSendButton: PropTypes.bool,
};

ShipsmartPickupSummaryStep.defaultProps = {
  fboOrigin: null,
  orders: null,
  restockOrderId: null,
  setLoadingConfirmShipments: null,
  hideSendButton: false,
};

export default forwardRef(ShipsmartPickupSummaryStep);
