import fileDownload from 'js-file-download';
import api from './api';

const prefix = '/listings';

const listingsApi = {
  getAll() {
    return api.get(`${prefix}`);
  },

  getDocument(partnerId) {
    return api.get(`${prefix}/doc/${partnerId}`);
  },

  test(partnerId) {
    return api.get(`${prefix}/partner/${partnerId}/mkt/amazon`);
  },

  getSyncStatus(partnerId) {
    return api.get(`${prefix}/partner/${partnerId}/sync/status`);
  },

  cancelSyncListings(partnerId) {
    return api.delete(`${prefix}/partner/${partnerId}/sync`);
  },

  getFromMkt(partnerId, marketplace) {
    return api.get(
      `${prefix}/partner/${partnerId}/marketplace/${marketplace}/sync`
    );
  },

  checkCreateListings(partnerId, marketplace, listing, productType) {
    return api.put(
      `${prefix}/partner/${partnerId}/marketplace/${marketplace}/productType/${productType}/check`,
      listing
    );
  },

  createListing(partnerId, marketplace, listing) {
    return api.post(
      `${prefix}/partner/${partnerId}/marketplace/${marketplace}`,
      listing
    );
  },

  syncFbaStock(payload) {
    return api.put(`${prefix}/fba/syncStock`, payload);
  },

  syncFbmStock(payload) {
    return api.put(`${prefix}/fbm/syncStock`, payload);
  },

  unlinkMatchesFBA(payload) {
    return api.put(`${prefix}/fba/unlinkMatches`, payload);
  },

  getMarketplacesAllowedByListingId(listingId) {
    return api.get(`${prefix}/${listingId}/sellerMarketplaces`);
  },

  async buildExportSheet(body) {
    const filename = `products_from_${body.originMarketplace}_to_${body.destinationMarketplace}.xlsx`;
    return api({
      url: `${prefix}/bulk-products/export`,
      method: 'PUT',
      responseType: 'blob',
      data: body,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  async buildUploadSheet(params, body) {
    const filename = `${body[0]?.categoryName.split(' > ').join('_')}.xlsx`;
    return api({
      url: `${prefix}/bulk-products/upload`,
      method: 'PUT',
      responseType: 'blob',
      params,
      data: body,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  async downloadProductsSheet(params) {
    let filename = 'products.xlsx';
    if (params?.currentMarketplace) {
      filename = `${params?.currentMarketplace}_products.xlsx`;
    }
    return api({
      url: `${prefix}/file/products-file`,
      method: 'GET',
      responseType: 'blob',
      params,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  uploadProductsSheet(file) {
    const formData = new FormData();
    formData.append('file', file);
    return api({
      url: `${prefix}/file/bulk-products`,
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};

export default listingsApi;
