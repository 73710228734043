import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import couriersAPI from '../../api/courier';
import openNotification from '../../components/Toastr';
import ShipmentTypeAndPriceCard from './components/ShipmentTypeAndPriceCard';

function FedExServiceSelect({
  shipments,
  setRatesAndTransitTimesPayload,
  setLoadingRates,
  setRates,
  setRate,
  selectedService,
  setSelectedService,
}) {
  const { t } = useTranslation();
  const language = useSelector((store) => store.Session.language);
  const [fedexServices, setFedexServices] = useState({});

  useEffect(() => {
    const getOrderRates = async () => {
      setSelectedService(undefined);
      setLoadingRates(true);
      let result = {};

      let promises = shipments.map(async (shipment, index) => {
        const payload = setRatesAndTransitTimesPayload(shipment);
        try {
          const { data: ratesData } = await couriersAPI.getRatesAndTransitTimes(
            'Fedex',
            payload
          );
          const shipmentRate = {};
          const services = {};
          ratesData.forEach((service) => {
            const details = {
              name: service.serviceName,
              serviceType: service.serviceType,
              commitDate: service?.operationalDetail?.commitDate
                ? new Date(service?.operationalDetail?.commitDate)
                : null,
              rates: {},
            };
            service.ratedShipmentDetails.forEach((detail) => {
              details.rates[detail.rateType] =
                detail.totalNetChargeWithDutiesAndTaxes ||
                detail.totalNetCharge;
              details.currency = detail.currency;
            });
            services[service.serviceType] = details;
          });
          shipmentRate[index] = services;
          result = { ...result, ...shipmentRate };
          setRates(result);
        } catch (error) {
          openNotification({ status: false, content: error });
        } finally {
          setLoadingRates(false);
        }
      });
      await Promise.all(promises);
      if (!Object.keys(result).length) return;
      // Show FedEx options that are common between all shipments
      const services = Object.values(result).map((shipmentRates) => {
        return new Set(Object.keys(shipmentRates));
      });
      const commonServices = services
        ? Array.from(
            services.reduce(
              (intersection, set) =>
                new Set([...intersection].filter((item) => set.has(item))),
              services[0]
            )
          )
        : [];
      const options = {};
      promises = [];

      // FedEx options will show the total cost of all the shipments
      commonServices.forEach((commonService) => {
        const promise = new Promise((resolve) => {
          const commonServiceRates = { rates: {} };
          Object.values(result).forEach((shipmentRate) => {
            const serviceRates = shipmentRate[commonService];
            commonServiceRates.name = serviceRates.name;
            commonServiceRates.currency = serviceRates.currency;
            commonServiceRates.commitDate = serviceRates?.commitDate;
            Object.entries(serviceRates.rates).forEach(
              ([rateType, rateData]) => {
                if (!commonServiceRates.rates[rateType]) {
                  commonServiceRates.rates[rateType] = 0;
                }
                commonServiceRates.rates[rateType] += rateData;
              }
            );
          });
          if (
            commonServiceRates.rates.LIST === commonServiceRates.rates.ACCOUNT
          ) {
            delete commonServiceRates.rates.LIST;
          }
          options[commonService] = commonServiceRates;
          resolve();
        });
        promises.push(promise);
      });
      await Promise.all(promises);
      setFedexServices(options);
    };
    getOrderRates();
  }, []);

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ width: '100%', marginTop: 15 }}
    >
      {Object.entries(fedexServices)
        .sort((a, b) => {
          let dateCompare = 0;
          if (a[1].commitDate && b[1].commitDate) {
            dateCompare =
              a[1].commitDate.setHours(0, 0, 0, 0) -
              b[1].commitDate.setHours(0, 0, 0, 0);
          }
          if (dateCompare === 0) {
            const priceCompare = a[1].rates.ACCOUNT - b[1].rates.ACCOUNT;
            return priceCompare;
          }
          return dateCompare;
        })
        .map(([key, option]) => (
          <ShipmentTypeAndPriceCard
            key={key}
            tag={
              option.rates.LIST
                ? `${
                    (1 - option.rates.ACCOUNT / option.rates.LIST).toFixed(2) *
                    100
                  }
                  % OFF`
                : null
            }
            title={option.name}
            date={`${t('common.estimatedDateOfDelivery')}: ${
              option.commitDate
                ? option.commitDate.toLocaleDateString(language)
                : '-'
            }`}
            totalCost={new Intl.NumberFormat('es-CL', {
              style: 'currency',
              currency: option.currency,
            }).format(option.rates.ACCOUNT)}
            discountCost={
              option.rates.LIST
                ? new Intl.NumberFormat('es-CL', {
                    style: 'currency',
                    currency: option.currency,
                  }).format(option.rates.LIST)
                : null
            }
            cardKey={key}
            checked={selectedService === key}
            onChange={(e) => {
              setSelectedService(e.target.value);
              setRate({
                rate: option.rates.ACCOUNT,
                currency: option.currency,
              });
            }}
          />
        ))}
    </Space>
  );
}

FedExServiceSelect.propTypes = {
  shipments: PropTypes.arrayOf(Object).isRequired,
  setLoadingRates: PropTypes.func.isRequired,
  setRates: PropTypes.func.isRequired,
  setRate: PropTypes.func,
  setRatesAndTransitTimesPayload: PropTypes.func.isRequired,
  selectedService: PropTypes.string.isRequired,
  setSelectedService: PropTypes.func.isRequired,
};

FedExServiceSelect.defaultProps = {
  setRate: () => {},
};

export default FedExServiceSelect;
