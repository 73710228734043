/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
// Pages
import { useSelector, useStore } from 'react-redux';
import * as queryString from 'query-string';
import { Spin } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import Home from './pages/home/Home';
import Ventas from './pages/home/Ventas';
import Inventario from './pages/home/Inventory';
import Calculadora from './pages/home/Calculator';
import Login from './pages/login/Login/Login';
import Register from './pages/login/Register/Register';
import NotFound from './pages/NotFound';
import MyAccount from './pages/myAccount/MyAccount';
// Auth
import { isExpired } from './helpers/auth-helpers';
import { initAxiosInterceptors } from './api/api';
// Redux
import AuthenticatedRoute from './routers/AuthenticatedRoute';
import Pedidos from './pages/order/Pedidos';
import SaleOrderReview from './pages/order/components/SaleOrderReview';
import NewOrder from './pages/order/NewOrder';
import Marketing from './pages/marketing/Index';
import Catalogue from './pages/catalogue/Catalogue';
import Imports from './pages/imports/Imports';
import ServerStatus from './pages/status/ServerStatus';
import accessKeysApi from './api/aws-access-keys';
import Couriers from './pages/shipments/Couriers';
import RequestAccount from './pages/shipments/components/fedex/request-account/RequestAccount';
import Warehouses from './pages/shipments/components/Warehouses';
import Regulations from './pages/regulations/Regulations';
import Plans from './pages/plans/Index';
import FedexSend from './pages/order/components/couriers/Fedex/FedexSend';
import Subscriptions from './pages/subscription/Subscriptions';
import MercadoPagoCheckout from './pages/subscription/components/MercadoPagoCheckout';
import PayPalCheckout from './pages/subscription/components/PayPalCheckout';
import ThirdPartyShipment from './pages/shipments/thirdPartyShipments/ThirdPartyShipment';
import ShipmentsTable from './pages/shipments/ShipmentsTable';
import ShipsmartSend from './pages/order/components/couriers/Shipsmart/ShipsmartSend';
import Assistant from './pages/regulations/Assistant';

function AppRouter() {
  const mixpanel = useMixpanel();
  const [loadingConsent, setLoadingConsent] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const session = useSelector((store) => store.Session.session);
  const store = useStore();

  const onPublicPath = [
    '/login',
    '/recover-password',
    '/planes',
    '/contratar-planes',
    '/reset-password',
  ].includes(window.location.pathname);

  const setShopifyConsentUrl = async () => {
    const qs = queryString.parse(window.location.search);
    if (qs && qs.hmac && qs.shop) {
      const shop = qs.shop.replace('.myshopify.com', '');
      // get correct url
      setLoadingConsent(false);
      try {
        const response = await accessKeysApi.getConsentUrl(0, 'Shopify');
        window.location = response.data.consentUrl.replace('%%shop%%', shop);
        setLoadingConsent(false);
      } catch (error) {
        window.location = '/login';
        setLoadingConsent(false);
      }
    }
  };

  useEffect(() => {
    const setIsAuthenticated = () => {
      let isAuthenticated = false;
      if (session && session?.userInfo?.login) {
        initAxiosInterceptors(store);
        mixpanel.identify(session.userInfo.login);
        if (window._cio) {
          window._cio.identify({
            id: session.userInfo.id,
            email: session.userInfo.login,
            name: session.userInfo.name,
            created_at: parseInt(
              new Date(session.userInfo.createdAt).getTime() / 1000,
              10
            ),
            last_succesful_login: Math.floor(Date.now() / 1000),
          });
        }
        mixpanel.people.set({ $name: session.userInfo.name });
        mixpanel.people.set({ $email: session.userInfo.login });
        mixpanel.people.set({ $createdAt: session.userInfo.createdAt });
        mixpanel.people.set({ $companyId: session.userInfo.company_id[0] });
        mixpanel.people.set({ $companyName: session.userInfo.company_id[1] });
        mixpanel.people.set({ $lapArea: session.userInfo.lapArea });
        mixpanel.people.set({ $companyActive: session.companyActive });
        mixpanel.people.set({
          $subscriptionType: session.userInfo.subscription?.type || '',
        });
        mixpanel.people.set({
          $subscriptionName: session.userInfo.subscription?.name || '',
        });
        if (!isExpired(session.token)) {
          isAuthenticated = true;
        }
      } else if ((!session || !session.token) && !onPublicPath) {
        setShopifyConsentUrl();
        isAuthenticated = false;
      } else {
        isAuthenticated = false;
      }
      setAuthLoaded(true);
      return isAuthenticated;
    };
    setIsAuthenticated();
  }, []);

  return (
    <Spin spinning={loadingConsent}>
      {!loadingConsent && authLoaded && (
        <Router>
          <div className="App">
            <Switch>
              <Route
                exact
                path="/learnworlds"
                render={() => {
                  const queryParams = new URLSearchParams(
                    window.location.search
                  );
                  const action = queryParams.get('action');
                  const redirectUrl = queryParams.get('redirectUrl');
                  let url = '/login';
                  if (action === 'login' && redirectUrl) {
                    url = '/login';
                    url += `?action=${action}&redirectUrl=${redirectUrl}`;
                  }
                  if (action === 'signup' && redirectUrl) {
                    url = '/register';
                    url += `?action=${action}&redirectUrl=${redirectUrl}`;
                  }
                  return <Redirect to={url} />;
                }}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route
                path="/my-account/seller-account/:marketplace/:consent"
                component={MyAccount}
              />
              <Route
                path="/contratar-planes"
                render={() => {
                  return session?.token ? (
                    <Redirect to="/planes" />
                  ) : (
                    <Plans loggedIn={false} />
                  );
                }}
              />

              <AuthenticatedRoute
                showSubscriptions
                path="/suscripciones"
                component={Subscriptions}
              />

              <AuthenticatedRoute
                path="/checkout/mercadoPago"
                component={MercadoPagoCheckout}
              />

              <AuthenticatedRoute
                path="/checkout/payPal"
                component={PayPalCheckout}
              />

              <AuthenticatedRoute exact path="/" component={Home} />
              <AuthenticatedRoute exact path="/ventas" component={Ventas} />
              <AuthenticatedRoute
                exact
                path="/inventario"
                component={Inventario}
              />

              {session?.success ? (
                <AuthenticatedRoute
                  exact
                  path="/calculadora"
                  component={Calculadora}
                />
              ) : (
                <Route exact path="/calculadora" component={Calculadora} />
              )}

              <AuthenticatedRoute
                path="/my-account"
                component={MyAccount}
                roles={['Admin', 'Client']}
              />
              <AuthenticatedRoute
                exact
                path="/envios/restock/:orderId"
                component={NewOrder}
              />
              <AuthenticatedRoute
                exact
                path="/envios"
                component={ShipmentsTable}
              />
              <AuthenticatedRoute
                path="/envios/a-terceros/:thirdPartyShipmentId"
                component={ThirdPartyShipment}
              />

              <AuthenticatedRoute exact path="/couriers" component={Couriers} />
              <AuthenticatedRoute
                path="/couriers/solicitar-cuenta"
                component={RequestAccount}
              />

              <AuthenticatedRoute path="/catalogo" component={Catalogue} />
              <AuthenticatedRoute path="/imports" component={Imports} />
              <AuthenticatedRoute exact path="/pedidos" component={Pedidos} />
              <AuthenticatedRoute
                path="/pedidos/:saleOrderId"
                component={SaleOrderReview}
              />
              <AuthenticatedRoute path="/publicidad" component={Marketing} />
              <AuthenticatedRoute path="/status" component={ServerStatus} />
              <AuthenticatedRoute path="/couriers" component={Couriers} />
              <AuthenticatedRoute path="/bodegas" component={Warehouses} />
              <AuthenticatedRoute
                path="/regulaciones"
                component={Regulations}
              />
              <AuthenticatedRoute
                path="/asistente-regulaciones"
                component={Assistant}
              />
              <AuthenticatedRoute path="/planes" component={Plans} />
              <AuthenticatedRoute path="/envio-fedex" component={FedexSend} />
              <AuthenticatedRoute
                path="/envio-shipsmart"
                component={ShipsmartSend}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
      )}
    </Spin>
  );
}
export default AppRouter;
