import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Timeline,
  Upload,
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import categoryApi from '../../../api/aws-category';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import listingsApi from '../../../api/listing';

function ExportProductsModal({
  marketplaceToExport,
  setMarketplaceToExport,
  selectedListings,
  originMarketplace,
  all,
  total,
}) {
  const { t } = useTranslation();

  const [matchedCategories, setMatchedCategories] = useState(null);
  const [productTypes, setProductTypes] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState({});
  const [allCategoriesMatched, setAllCategoriesMatched] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [excludePublished, setExcludePublished] = useState(null);

  const session = useSelector((store) => store.Session.session);

  const exportCompanies = [
    'tech+qnex@lapmarketplace.com',
    'tech+rocket@lapmarketplace.com',
    'braian@remixcom.com',
  ];

  const PRODUCT_LIMIT = 8000;

  const handleModalCancel = () => {
    setExcludePublished(null);
    setMarketplaceToExport(null);
    setMatchedCategories(null);
    setProductTypes({});
  };

  const handleModalOk = () => {
    setMarketplaceToExport(null);
    setMatchedCategories(null);
    setProductTypes({});
  };

  useEffect(() => {
    if (marketplaceToExport && total > PRODUCT_LIMIT) {
      setMarketplaceToExport(null);
      openNotification({
        status: false,
        content: t('catalogue.publishProductsModal.limitError', {
          limit: PRODUCT_LIMIT,
        }),
      });
    }
  }, [marketplaceToExport]);

  useEffect(() => {
    setAllCategoriesMatched(true); // To Do: Fix until complete not found suggestions
    /* matchedCategories &&
        Object.values(matchedCategories)?.every(
          (c) => c.productType !== null && c.productType !== undefined
        ) */
  }, [matchedCategories]);

  const onCategorize = async (newExcludePublished) => {
    try {
      setExcludePublished(newExcludePublished);
      setLoading(true);
      const payload = {
        defaultCodes: selectedListings,
        originMarketplace,
        destinationMarketplace: marketplaceToExport,
      };
      if (all) payload.all = all;
      if (newExcludePublished) payload.excludePublished = newExcludePublished;
      const { data } = await categoryApi.getMatchedCategories(payload);
      setMatchedCategories(data);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
      setMarketplaceToExport(null);
    } finally {
      setLoading(false);
    }
  };

  const handleProductTypeSearch = (newValue, id) => {
    if (newValue) {
      setFetchingOptions({ ...fetchingOptions, [id]: true });
      categoryApi
        .getByMarketplaceAndSearch(marketplaceToExport, newValue)
        .then((response) => {
          if (!marketplaceToExport?.includes('Amazon')) {
            setProductTypes({
              ...productTypes,
              [id]: response.data.map((item) => ({
                value: {
                  productType: item.identifier,
                  completeName: item.completeName,
                },
                text: item.completeName,
              })),
            });
          } else {
            const processedResponse = response.data?.map((item) => ({
              value: JSON.stringify({
                productType: item.productType,
                completeName: item.completeName,
                supported: item.supported,
              }),
              label: item.completeName,
              text: item.completeName,
              supported: item.supported,
            }));
            setProductTypes({
              ...productTypes,
              [id]: [
                {
                  label: t('catalogue.publishProductsModal.uploadLAP'),
                  options: processedResponse.filter(
                    (category) => category.supported === 1
                  ),
                },
                {
                  label: t('catalogue.publishProductsModal.uploadAmazon'),
                  options: processedResponse.filter(
                    (category) => category.supported === 0
                  ),
                },
              ],
            });
          }
          setFetchingOptions({ ...fetchingOptions, [id]: false });
        })
        .catch(() => setFetchingOptions({ ...fetchingOptions, [id]: false }));
    } else {
      setProductTypes({
        ...productTypes,
        [id]: [],
      });
    }
  };

  const debouncedHandleProductTypeSearch = debounce(
    handleProductTypeSearch,
    500
  );

  const handleProductTypeChange = (newValue, id) => {
    const newValueArray = JSON.parse(newValue);
    if (!marketplaceToExport?.includes('Amazon')) {
      const newMatchedCategories = { ...matchedCategories };
      const newCategory = productTypes[id]?.find(
        (type) =>
          type.value.productType === newValueArray.productType &&
          type.value.completeName === newValueArray.completeName
      );
      newMatchedCategories[id].categoryName = newCategory?.value.completeName;
      newMatchedCategories[id].categoryId = newCategory?.value.productType;
      setMatchedCategories(newMatchedCategories);
    } else if (newValueArray.supported === 0) {
      // showWarningModal();
    } else {
      const supportedCategories = productTypes[id]?.find(
        (category) =>
          category.label === t('catalogue.publishProductsModal.uploadLAP')
      );
      const processedCategories = supportedCategories?.options.map((item) => ({
        ...item,
        value: JSON.parse(item.value),
      }));
      const newMatchedCategories = { ...matchedCategories };
      const newCategory = processedCategories.find(
        (type) =>
          type.value.productType === newValueArray.productType &&
          type.value.completeName === newValueArray.completeName
      );
      newMatchedCategories[id].categoryName = newCategory?.value.completeName;
      newMatchedCategories[id].categoryId = newCategory?.value.productType;
      setMatchedCategories(newMatchedCategories);
    }
  };

  const selectOptions = (id) => {
    if (!marketplaceToExport?.includes('Amazon')) {
      return (productTypes[id] || []).map((d) => ({
        value: JSON.stringify(d.value),
        label: d.text,
      }));
    }
    return productTypes[id] || [];
  };

  const handleDownload = async () => {
    const skusByCategory = Object.values(matchedCategories).filter(
      (c) => c.categoryId !== null && c.categoryId !== undefined
    );
    const body = {
      originMarketplace,
      destinationMarketplace: marketplaceToExport,
      skusByCategory,
    };
    setLoading(true);
    return listingsApi
      .buildExportSheet(body)
      .catch((error) => {
        if (
          error.response.status === 400 &&
          marketplaceToExport?.includes('Amazon')
        )
          openNotification({
            status: false,
            content: t('products.errors.categoryNotAllowed'),
            helpUrl:
              'https://ayuda.lapmarketplace.com/hc/es-cl/articles/16428729142555',
          });
        else
          openNotification({
            status: false,
            content: getErrorMessage(error),
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const beforeUpload = (file) => {
    setUploadFile(file);
    return false;
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      listingsApi.uploadProductsSheet(uploadFile).then(() => {
        window.location = '/imports';
      });
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="publishProductsModal"
      onOk={handleModalOk}
      open={
        exportCompanies.includes(session.userInfo.email) &&
        marketplaceToExport &&
        total > 0 &&
        total < PRODUCT_LIMIT
      }
      onCancel={handleModalCancel}
      width="70%"
      title={t('catalogue.exportProductsModal', {
        originMarketplace,
        marketplaceToExport,
      })}
      footer={false}
    >
      <Spin spinning={loading}>
        <Timeline>
          <Timeline.Item>
            <Space size="middle">
              <Space>
                <Button
                  size="small"
                  type={excludePublished === true ? 'primary' : 'secondary'}
                  icon={<CheckOutlined />}
                  onClick={() => onCategorize(true)}
                />
                <Button
                  size="small"
                  type={excludePublished === false ? 'primary' : 'secondary'}
                  icon={<CloseOutlined />}
                  onClick={() => onCategorize(false)}
                />
              </Space>
              {t('catalogue.publishProductsModal.excludePublished', {
                marketplace: marketplaceToExport,
              })}
            </Space>
          </Timeline.Item>
          {matchedCategories ? (
            <>
              <Timeline.Item>
                <Row
                  justify="space-between"
                  gutter={[16, 16]}
                  style={
                    Object.keys(matchedCategories).length > 7
                      ? {
                          maxHeight: 350,
                          overflowY: 'auto',
                          marginBottom: 20,
                        }
                      : { marginBottom: 20 }
                  }
                >
                  <Col span={24}>
                    {t('catalogue.publishProductsModal.destinationCategories')}
                  </Col>
                  {matchedCategories?.NOT_FOUND?.skus.length > 0 && (
                    <Col span={24}>
                      <Space>
                        <WarningOutlined />
                        {t(
                          'catalogue.publishProductsModal.notFoundCategories',
                          {
                            productIds:
                              matchedCategories?.NOT_FOUND?.skus.join(' / '),
                          }
                        )}
                      </Space>
                    </Col>
                  )}
                  <Col
                    span={11}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {t('products.fields.originProducts')}
                  </Col>
                  <Col span={2} />
                  <Col
                    span={11}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {t('products.fields.destinationCategory')}
                  </Col>
                  {matchedCategories &&
                    Object.entries(matchedCategories).map(
                      ([index, match]) =>
                        match.categoryId && (
                          <>
                            <Col span={11}>
                              <Input value={match.skus.join(' / ')} />
                            </Col>
                            <Col
                              span={2}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <ArrowRightOutlined />
                            </Col>
                            <Col span={11}>
                              <Select
                                id={`publish-products-category-select-${index}`}
                                showSearch
                                // disabled={!canSelectCategory}
                                notFoundContent={
                                  fetchingOptions[index] ? (
                                    <Row justify="space-around">
                                      <Space>
                                        <Spin size="small" />
                                        {t(
                                          'catalogue.publishProductsModal.searching'
                                        )}
                                      </Space>
                                    </Row>
                                  ) : null
                                }
                                value={match.categoryName}
                                defaultActiveFirstOption={false}
                                placeholder={
                                  marketplaceToExport === 'Amazon' ||
                                  marketplaceToExport === 'Ebay' ||
                                  marketplaceToExport === 'Walmart' ||
                                  marketplaceToExport === 'Amazon Canada' ||
                                  marketplaceToExport === 'Ebay Canada'
                                    ? t(
                                        'catalogue.publishProductsModal.searchCategoriesPlaceholder'
                                      )
                                    : t(
                                        'catalogue.publishProductsModal.spanishSearchCategoriesPlaceholder'
                                      )
                                }
                                showArrow={false}
                                filterOption={false}
                                style={{
                                  width: '100%',
                                }}
                                onSearch={(newValue) =>
                                  debouncedHandleProductTypeSearch(
                                    newValue,
                                    index
                                  )
                                }
                                onChange={(newValue) =>
                                  handleProductTypeChange(newValue, index)
                                }
                                options={selectOptions(index)}
                              />
                            </Col>
                          </>
                        )
                    )}
                </Row>
              </Timeline.Item>
              <Timeline.Item style={{ paddingBottom: 0 }}>
                <Row gutter={[16, 32]}>
                  <Col span={24}>
                    <Row className="buttonsContainer" justify="space-between">
                      <Col
                        span={11}
                        className="leftButtonsContainer step4FirstPost"
                      >
                        <Paragraph>
                          {t(
                            'catalogue.publishProductsModal.downloadTemplateCategory'
                          )}
                        </Paragraph>
                        <Button
                          className="btn-basic leftButton width100"
                          onClick={handleDownload}
                          disabled={!allCategoriesMatched}
                        >
                          {t('common.download')}
                        </Button>
                      </Col>
                      <Col
                        span={11}
                        className="rightButtonsContainer step5FirstPost"
                      >
                        <Paragraph>
                          {t(
                            'catalogue.publishProductsModal.uploadTemplateCategory'
                          )}
                        </Paragraph>
                        <Upload
                          maxCount={1}
                          name="file"
                          beforeUpload={beforeUpload}
                          className="uploadProductsFile width100"
                        >
                          <Button
                            className="btn-basic width100 rightButton"
                            disabled={!allCategoriesMatched}
                          >
                            {t('common.uploadFile')}
                          </Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        className="btn-basic-green step6FirstPost"
                        disabled={!uploadFile}
                        onClick={handleUpload}
                        style={{ width: '20%' }}
                      >
                        {t('products.fields.uploadAction')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Timeline.Item>
            </>
          ) : (
            <div style={{ height: 300 }} />
          )}
        </Timeline>
      </Spin>
    </Modal>
  );
}

ExportProductsModal.propTypes = {
  marketplaceToExport: PropTypes.string.isRequired,
  setMarketplaceToExport: PropTypes.func.isRequired,
  selectedListings: PropTypes.arrayOf(PropTypes.string).isRequired,
  originMarketplace: PropTypes.string.isRequired,
  all: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default ExportProductsModal;
